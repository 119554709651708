import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faArrowAltLeft } from '@fortawesome/pro-light-svg-icons'

const Keypad = ({ setProductAmount, openKeypad, keypadProduct }) => {
    const [amount, setAmount] = useState(keypadProduct.currentAmount)

    const sendRequest = () => {
        openKeypad(false)
        setProductAmount(keypadProduct.eanCode, amount)
    }

    return (
        <div className='keypad'>
            <button type='button' className='btn close decrease' onClick={() => openKeypad(false)}>
                <FontAwesomeIcon icon={faXmark} />
            </button>
            <div className='name'><p>{keypadProduct.name}</p></div>
            <div className='keys'>
                <table>
                    <tbody>
                        <tr className='count-tab'>
                            <td colSpan='3'>
                                <div className='count'>
                                    <p>{amount}</p>
                                </div>
                            </td>
                            <td>
                                <button type='button' className='btn-digit'  onClick={(e) => setAmount(parseInt(amount / 10))}>
                                    <FontAwesomeIcon icon={faArrowAltLeft} />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setAmount(amount * 10 + parseInt(e.target.innerText))}>7</button>
                            </td>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setAmount(amount * 10 + parseInt(e.target.innerText))}>8</button>
                            </td>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setAmount(amount * 10 + parseInt(e.target.innerText))}>9</button>
                            </td>
                            <td rowSpan='2'>
                                <button type='button' className='btn-digit decrease' onClick={() => setAmount(0)}>C</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setAmount(amount * 10 + parseInt(e.target.innerText))}>4</button>
                            </td>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setAmount(amount * 10 + parseInt(e.target.innerText))}>5</button>
                            </td>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setAmount(amount * 10 + parseInt(e.target.innerText))}>6</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setAmount(amount * 10 + parseInt(e.target.innerText))}>1</button>
                            </td>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setAmount(amount * 10 + parseInt(e.target.innerText))}>2</button>
                            </td>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setAmount(amount * 10 + parseInt(e.target.innerText))}>3</button>
                            </td>
                            <td rowSpan='2'>
                                <button type='button' className='btn-digit submit' onClick={sendRequest}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='3'>
                                <button type='button' className='btn-digit' onClick={(e) => setAmount(amount * 10 + parseInt(e.target.innerText))}>0</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Keypad