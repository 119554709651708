import React from 'react'
import Topmenu from './menus/Topmenu'

const ErrorSite = () => {
    return (
        <>
            <Topmenu />
            <div className="error-site">
                <h1>Chyba 404</h1>
                <p>Stránka nenalezena</p>
            </div>
        </>
    )
}

export default ErrorSite