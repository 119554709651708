import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'

const ProgressLoader = () => {
    return (
        <div className="loader progress-loader">
            <FontAwesomeIcon icon={faSpinnerThird} />
        </div>
    )
}

export default ProgressLoader