import React, { useContext, useState, useEffect, createContext } from 'react'
import { AppContext } from './Settings'
import SettingsList from './SettingsList'
import { UsersList, UsersForm } from '.'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { toast } from 'react-toastify';
import { Buffer } from 'buffer';
import { Row } from 'react-bootstrap'
import axios from 'axios'

export const UserContext = createContext(null)

const Users = () => {
    const { setComponent } = useContext(AppContext)
    const [users, setUsers] = useState([])

    const getUsers = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'users'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            setUsers(response.data)
        } catch (error) {
            console.log(error);
        }
    }

    const saveUser = async (user) => {
        const userUtf = JSON.stringify(user)
        const data = JSON.stringify({ userBase: Buffer.from(userUtf, 'utf-8').toString('base64') })
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + "/bufet/adduser",
                {
                    data: data,
                },
                {
                    params: {
                        database: 'bufet',
                        table: 'users',
                    },
                    headers: {
                        Authorization: process.env.REACT_APP_API_TOKEN
                    }
                }
            )
            if (response.data.added) {
                return toast.success('Uživatel přidán')
            }
            toast.success('Při přidávání uživatele nastala chyba')
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getUsers()
    }, [])


    return (
        <div className='setting'>
            <div className="setting__header">
                <button className='btn' onClick={() => {setComponent(<SettingsList />)}}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <h3>Nastavení uživatelů</h3>
            </div>
            <Row className="setting__body">
                <UserContext.Provider value={{ users, saveUser }}>
                    <UsersList />
                    <UsersForm />
                </UserContext.Provider>
            </Row>
        </div>
    )
}

export default Users