import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ButtonLoader } from '../loaders'
import { toast } from 'react-toastify'

const PasswordReset = ({ resetPass }) => {
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [passwordRe, setPasswordRe] = useState('')

    const sendRequest = (e) => {
        e.preventDefault()
        if (password !== passwordRe) {
            return toast.error('Hesla se neshodují')
        }
        resetPass(name, password)
    }

    return (
        <div className="login">
            <div className="login__header">
                <h1 className='no-wrap'>Reset hesla</h1>
           </div>
            <form className="form" onSubmit={sendRequest} autoComplete="off" >
                <div className="form-group">
                    <label htmlFor="name">Uživatelské jméno</label>
                    <input type="text" value={name} id="name" onChange={(e) => {setName(e.target.value)}} placeholder="Uživatelské jméno" required />
                </div>
                <div className="form-group">
                    <label htmlFor="newpass">Nové heslo</label>
                    <input type="password" value={password} id="newpass" onChange={(e) => {setPassword(e.target.value)}} placeholder="Nové heslo" required />
                </div>
                <div className="form-group">
                    <label htmlFor="renewpass">Znovu nové heslo</label>
                    <input type="password" value={passwordRe} id="renewpass" onChange={(e) => {setPasswordRe(e.target.value)}} placeholder="Znovu nové heslo" required />
                </div>
                <button type="submit" className="btn btn-submit">Resetovat</button>
            </form>
            <Link to="/" className='reset-link'>Přihlásit se</Link>
        </div>
    )
}

export default PasswordReset