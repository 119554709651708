import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faBrightness, faMoon, faExpand, faCompress } from '@fortawesome/pro-light-svg-icons'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import { Buffer } from 'buffer'

const Menu = ({ theme, themeSwitcher }) => {
    const [icon, setIcon] = useState(faMoon)
    const [iconScreen, setIconScreen] = useState(faExpand)
    const userBase = localStorage.getItem('user')
    const dateBase = localStorage.getItem('turnus')
    const permission = Buffer.from(userBase, 'base64').toString('utf-8').split(':')[2]
    const turnusState = (Buffer.from(dateBase, 'base64').toString('utf-8')).split(':')[2]

    const menu = () => {
        var elem = document.querySelector('nav.menu');
        if (elem.classList.contains('open')) {
            elem.classList.remove('open')
        } else {
            elem.classList.add('open')
        }
    }

    const fullscreen = () => {
        var elem = document.querySelector('body')
        if (!document.fullscreenElement) {
            elem.requestFullscreen()
                .then(
                    setIconScreen(faCompress)
                ).catch((err) => {
                    toast.error('Nepodařilo se zapnout fullscreen')
                    console.log(err);
                })
            } else {
                document.exitFullscreen()
                .then(
                    setIconScreen(faExpand)
                ).catch((err) => {
                    toast.error('Nepodařilo se ukončit fullscreen')
                    console.log(err);
                })
        }
    }

    const logOut = () => {
        Cookies.remove('user')
        window.location.reload()
    }

    useEffect(() => {
        setIcon(theme ? faBrightness : faMoon)
    }, [theme])

    return (
        <>
            <nav className="menu">
                <button type="button" className="btn close-menu" onClick={menu}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
                <ul>
                    <li><Link to='/' onClick={menu}>Domů</Link></li>
                    {turnusState === 'on' && <li><Link to='/bufet' onClick={menu}>Kasa</Link></li>}
                    {(parseInt(permission) > 1) && <li><Link to='/sklad' onClick={menu}>Sklad</Link></li>}
                    {(parseInt(permission) > 2 && turnusState === 'on') && <li><Link to='/objednavky' onClick={menu}>Objednávky</Link></li>}
                    {parseInt(permission) > 2 && <li><Link to='/prehledy' onClick={menu}>Přehledy</Link></li>}
                    {parseInt(permission) > 3 && <li><Link to='/nastaveni' onClick={menu}>Nastavení</Link></li>}
                    <li><a onClick={logOut}>Odhlásit</a></li>
                </ul>
                <div className="other-options">
                    <button type="button" className="btn fullscreen" onClick={fullscreen}>
                        <FontAwesomeIcon icon={iconScreen} />
                    </button>
                    <button type="button" className="btn theme-switch" onClick={themeSwitcher}>
                        <FontAwesomeIcon icon={icon} />
                    </button>
                </div>
            </nav>
        </>
    )
}

export default Menu