import React from 'react'

const OrderDetailProduct = ({ product, orderProduct }) => {
    return (
        <li className='order__item'>
            <p className="item-name"><b>•</b> {product.name}</p>
            <p className='item-amount'>{orderProduct.amount}</p>
            <p className="item-price"><b>{product.price * orderProduct.amount} Kč</b>/{product.price} Kč</p>
        </li>
    )
}

export default OrderDetailProduct