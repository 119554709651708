import React from 'react'
import { Order } from '../'

const OrderList = ({ orders, products, getOrders }) => {
    return (
        <ul className='orders__list'>
            {orders.sort((a, b) => (a.id < b.id) ? 1 : -1).map(order => {
                return <Order order={order} products={products} getOrders={getOrders} key={order.id} />
            })}
        </ul>
    )
}

export default OrderList