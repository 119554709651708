import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Topmenu, OrderList } from '../'
import { Loader } from '../loaders';
import axios from 'axios'
import { Buffer } from 'buffer'

const Orders = () => {
    const navigate = useNavigate()
    const dateBase = localStorage.getItem('turnus')
    const userBase = localStorage.getItem('user')
    const [products, setProducts] = useState([])
    const [orders, setOrders] = useState([])
    const [turnus, setTurnus] = useState({})
    const [areaInfo, setAreaInfo] = useState({})
    const [turnuses, setTurnuses] = useState([])
    const [areas, setAreas] = useState([])
    const [status, setStatus] = useState('fetching')
    const [areaId, setAreaId] = useState(Buffer.from(userBase, 'base64').toString('utf-8').split(':')[1].split('-')[1])
    const [turnusId, setTurnusId] = useState((Buffer.from(dateBase, 'base64').toString('utf-8')).split(':')[0])
    const [sumPrice, setSumPrice] = useState(0)
    const permission = Buffer.from(userBase, 'base64').toString('utf-8').split(':')[2]

    const getOrders = async () => {
        try {
            const responseProducts = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'products'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const turnusData = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'turnuses',
                    type: 'id',
                    specific: parseInt(turnusId)
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const responseOrders = await axios.get(process.env.REACT_APP_API_URL + '/bufet/orders', {
                params: {
                    database: 'bufet',
                    table: 'orders',
                    from: turnusData.data[0].dateFrom,
                    to: turnusData.data[0].dateTo,
                    area: areaId
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const orderProducts = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'order_products'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const areaData = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'areas',
                    type: 'id',
                    specific: parseInt(areaId)
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const areasData = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'areas',
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const turnusesData = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'turnuses'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            responseOrders.data.data.map(order => {
                order.products = []
                orderProducts.data.map(product => {
                    if (order.orderId === product.orderId) {
                        order.products.push(product)
                    }
                })
            })
            setTurnus(turnusData.data[0])
            setAreaInfo(areaData.data[0])
            setProducts(responseProducts.data)
            setOrders(responseOrders.data.data)
            setAreas(areasData.data)
            setTurnuses(turnusesData.data)
            setStatus('fetched')
        } catch (err) {
            console.log(err);
        }
    }

    const filterOrders = async () => {
        const turnusData = await axios.get(process.env.REACT_APP_API_URL + '/site', {
            params: {
                database: 'bufet',
                table: 'turnuses',
                type: 'id',
                specific: parseInt(turnusId)
            },
            headers: {
                Authorization: process.env.REACT_APP_API_TOKEN
            }
        })
        const responseOrders = await axios.get(process.env.REACT_APP_API_URL + '/bufet/orders', {
            params: {
                database: 'bufet',
                table: 'orders',
                from: turnusData.data[0].dateFrom,
                to: turnusData.data[0].dateTo,
                area: parseInt(areaId)
            },
            headers: {
                Authorization: process.env.REACT_APP_API_TOKEN
            }
        })
        const orderProducts = await axios.get(process.env.REACT_APP_API_URL + '/site', {
            params: {
                database: 'bufet',
                table: 'order_products'
            },
            headers: {
                Authorization: process.env.REACT_APP_API_TOKEN
            }
        })
        const areaData = await axios.get(process.env.REACT_APP_API_URL + '/site', {
            params: {
                database: 'bufet',
                table: 'areas',
                type: 'id',
                specific: parseInt(areaId)
            },
            headers: {
                Authorization: process.env.REACT_APP_API_TOKEN
            }
        })
        responseOrders.data.data.map(order => {
            order.products = []
            orderProducts.data.map(product => {
                if (order.orderId === product.orderId) {
                    order.products.push(product)
                }
            })
        })
        setTurnus(turnusData.data[0])
        setAreaInfo(areaData.data[0])
        setOrders(responseOrders.data.data)
        setStatus('fetched')
    }

    const resetFilters = () => {
        setStatus('fetching')
        setAreaId((Buffer.from(userBase, 'base64').toString('utf-8')).split(':')[1].split('-')[1])
        setTurnusId((Buffer.from(dateBase, 'base64').toString('utf-8')).split(':')[0])
        filterOrders()
    }

    useEffect(() => {
        if (parseInt(permission) < 3) {
            return navigate('/unauthorized')
        }
        getOrders()
    }, [])

    const startFilter = () => {
        setStatus('fetching')
        filterOrders()
    }

    return (
        <>
            <Topmenu nogradient />
            {status === 'fetching' ?
                <Loader /> :
                <div className="main orders">
                    <div className="orders__header">
                        <h2>Objednávky</h2>
                        <span>{areaInfo?.name}, {turnus?.name}: {new Date(turnus?.dateFrom).getDate()}.&nbsp;
                                                                {new Date(turnus?.dateFrom).getMonth() + 1}.&nbsp;
                                                                {new Date(turnus?.dateFrom).getFullYear()}&nbsp;
                                                                -&nbsp;
                                                                {new Date(turnus?.dateTo).getDate()}.&nbsp;
                                                                {new Date(turnus?.dateTo).getMonth() + 1}.&nbsp;
                                                                {new Date(turnus?.dateTo).getFullYear()}&nbsp;
                        </span>
                        {parseInt(permission) > 3 &&
                            <div className="orders__filters form">
                                    <div className="form-group">
                                        <select value={parseInt(areaId)} onChange={(e) => {setAreaId(e.target.value)}} className="form-select">
                                            {areas?.map(area => {
                                                return <option value={area.id} key={area.id}>{area.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <select value={parseInt(turnusId)} onChange={(e) => {setTurnusId(e.target.value)}} className="form-select">
                                            {turnuses?.map(turnusData => {
                                                if (turnusData.area === parseInt(areaId)) {
                                                    return <option value={parseInt(turnusData.id)} key={turnusData.id} >{turnusData.name}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                <button className="btn btn-submit" type='button' onClick={startFilter}>Filtrovat</button>
                                <button className="btn btn-submit" type='button' onClick={resetFilters}>Zrušit filtry</button>
                            </div>
                        }
                    </div>
                    {orders.length > 0 ?
                        <OrderList products={products} orders={orders} getOrders={getOrders} />:
                        <h4>Nebyly nalezeny žádné objednávky</h4>
                    }
                </div>
            }
        </>
    )
}

export default Orders