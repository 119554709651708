import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltLeft } from '@fortawesome/pro-light-svg-icons'

const Keypad = ({ number, setNumber }) => {
    return (
        <div className='keypad paytime__keypad'>
            <div className='keys'>
                <table>
                    <tbody>
                        <tr className='count-tab'>
                            <td colSpan='4'>
                                <div className='count'>
                                    <p>Dáno: {number}Kč</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setNumber(number * 10 + parseInt(e.target.innerText))}>7</button>
                            </td>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setNumber(number * 10 + parseInt(e.target.innerText))}>8</button>
                            </td>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setNumber(number * 10 + parseInt(e.target.innerText))}>9</button>
                            </td>
                            <td rowSpan='2'>
                                <button type='button' className='btn-digit btn-backspace'  onClick={(e) => setNumber(parseInt(number / 10))}>
                                    <FontAwesomeIcon icon={faArrowAltLeft} />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setNumber(number * 10 + parseInt(e.target.innerText))}>4</button>
                            </td>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setNumber(number * 10 + parseInt(e.target.innerText))}>5</button>
                            </td>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setNumber(number * 10 + parseInt(e.target.innerText))}>6</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setNumber(number * 10 + parseInt(e.target.innerText))}>1</button>
                            </td>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setNumber(number * 10 + parseInt(e.target.innerText))}>2</button>
                            </td>
                            <td>
                                <button type='button' className='btn-digit' onClick={(e) => setNumber(number * 10 + parseInt(e.target.innerText))}>3</button>
                            </td>
                            <td rowSpan='2'>
                                <button type='button' className='btn-digit decrease' onClick={() => setNumber(0)}>C</button>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='3'>
                                <button type='button' className='btn-digit' onClick={(e) => setNumber(number * 10 + parseInt(e.target.innerText))}>0</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Keypad