import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faXmark, faFloppyDisk } from '@fortawesome/pro-light-svg-icons'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Buffer } from 'buffer'

const StockItem = ({ product, getProducts, setEditProduct, defaultValue }) => {
    const turnusBase = localStorage.getItem('turnus')
    const turnusId = parseInt(Buffer.from(turnusBase, 'base64').toString('utf-8').split(':')[0])

    const deleteProduct = async (id) => {
        try {
            const response = await axios.delete(process.env.REACT_APP_API_URL + '/site', {
                data: {
                    type: 'id',
                    specific: id
                },
                params: {
                    database: 'bufet',
                    table: 'products'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            getProducts()
            toast.success(`Produkt odstraněn`)
        } catch (err) {
            toast.error(`Při odstraňování produktu nastala chyba`)
            console.log(err);
        }
    }

    const setDefault = async (id) => {
        try {
            let n = 0
            defaultValue.map(value => {
                if (value.productId === id && value.turnusId === turnusId) {
                    n++
                    return toast.error('Výchozí hodnota na tento turnus je již nastavena')
                }
            })
            if (n === 0) {
                const data = {
                    turnusId,
                    productId: parseInt(id),
                    amount: product.amount
                }
                const dataJSON = JSON.stringify(data)
                await axios.post(process.env.REACT_APP_API_URL + '/site', {
                        data: dataJSON
                    },
                    {
                        params: {
                            database: 'bufet',
                            table: 'defaultValues'
                        },
                        headers: {
                            Authorization: process.env.REACT_APP_API_TOKEN
                        }
                    }
                )
                toast.success(`Výchozí hodnota produktu ${product.name} byla nastavena`)
                getProducts()
            }
        } catch (error) {
            console.log(error);
            toast.error(`Nepovedlo se nastavit výchozí hodnotu pro ${product.name}`)
        }
    }

    return (
        <li className="item">
            <p className="item-name">{product.name}</p>
            <div>
                <p className="item-price">{product.price} Kč</p>
                <p className="item-amout">{product.amount} ks</p>
                <button type="button" className="btn btn-normal btn-edit" onClick={() => setEditProduct(product)}>
                    <FontAwesomeIcon icon={faPen} />
                </button>
                <button type="button" className="btn btn-normal" onClick={() => setDefault(product.id)}>
                    <FontAwesomeIcon icon={faFloppyDisk} />
                </button>
                <button type="button" className="btn btn-danger">
                    <FontAwesomeIcon icon={faXmark} onClick={() => deleteProduct(product.id)} />
                </button>
            </div>
        </li>
    )
}

export default StockItem