import React, { useState, useEffect } from 'react'
import { Topmenu, Bottommenu, Items, Summary, Keypad, Paytime } from '../'
import { Loader } from '../loaders';
import axios from 'axios'
import { toast } from 'react-toastify';
import { Buffer } from 'buffer'

const Buffet = () => {
    const [products, setProducts] = useState([])
    const [showProducts, setShowProducts] = useState([])
    const [categories, setCategories] = useState([])
    const [status, setStatus] = useState('fetching')
    const [update, setUpdate] = useState(false)
    const [price, setPrice] = useState(0)
    const [keypad, setKeypad] = useState(false)
    const [keypadProduct, setKeypadProduct] = useState('')
    const [searchText, setSearchText] = useState('')
    const [paytime, setPaytime] = useState(false)
    const [paytimeProgress, setPaytimeProgress] = useState('nothing')
    const userBase = localStorage.getItem('user')
    const area = Buffer.from(userBase, 'base64').toString('utf-8').split(':')[1].split('-')[1]
    const getProducts = async () => {
        try {
            const responseProducts = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'products',
                    type: 'area',
                    specific: parseInt(area)
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const responseCategories = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'categories'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            responseProducts.data.map(product => {
                product.currentAmount = 0
            })
            responseCategories.data.map(category => {
                category.products = 0
                for (const product of responseProducts.data) {
                    if (category.name === product.category) {
                        category.products++
                    }
                }
            })
            setProducts(responseProducts.data)
            setShowProducts(responseProducts.data)
            setCategories(responseCategories.data)
            setStatus('fetched')
        } catch (err) {
            console.log(err);
        }
    }

    const count = (ean, add) => {
        if (add) {
            increase(ean)
        } else {
            decrease(ean)
        }
    }

    const increase = (ean, scan) => {
        const productToChange = products.find(product => {return product.eanCode === ean})
        if (!productToChange && scan) {
            return toast.error(`Produkt nenalezen`)
        }
        if (!scan && productToChange.currentAmount === productToChange.amount && ean === productToChange.eanCode) {
            return toast.error('Víc toho na skladě není')
        }
        if (productToChange.eanCode === ean && productToChange.currentAmount < productToChange.amount) {
            productToChange.currentAmount++
            if (scan) {
                toast.success(`Přidáno: ${productToChange.name}`)
            }
        }
        changeProducts()
    }

    const decrease = (ean) => {
    const productToChange = products.find(product => {return product.eanCode === ean})
    if (productToChange.currentAmount > 0) {
        productToChange.currentAmount--
    }
        changeProducts()
    }

    const changeProducts = () => {
        setProducts(products)
        setUpdate(!update)
        countUp()
    }

    const countUp = () => {
        var sume = 0
        for (const product of products) {
            var pricePerProduct = product?.price * product?.currentAmount
            sume += pricePerProduct
        }
        setPrice(sume)
    }

    const setProductAmount = (eanCode, amount) => {
        for (const product of products) {
            if (product.eanCode === eanCode) {
                if (product.amount < amount) {
                    product.currentAmount = product.amount
                    toast.error(`Na skladě je pouze ${product.amount} kusů`)
                } else {
                    product.currentAmount = amount
                }
            }
        }
        changeProducts()
    }

    const openKeypad = (open, product) => {
        setKeypadProduct(product)
        setKeypad(open)
    }

    const startPayment = () => {
        if (price > 0) {
            setPaytime(true)
        } else {
            toast.error('Košík je prázdný')
        }
    }

    const newOrder = async (price) => {
        setPaytimeProgress('loading')
        const selledPoducts = []
        products.map(product => {
            if (product.currentAmount > 0) {
                selledPoducts.push({ productId: product.id,product: product.eanCode, amount: product.currentAmount})
            }
        })
        const orderData = {
            products: selledPoducts,
            price: price,
            area: parseInt(area)
        }
        const dataJSON = JSON.stringify(orderData)
        try {
            const order = await axios.post(process.env.REACT_APP_API_URL + "/bufet/order",
                    {
                        data: dataJSON,
                    },
                    {
                        params: {
                            database: 'bufet',
                            table: 'orders',
                        },
                        headers: {
                            Authorization: process.env.REACT_APP_API_TOKEN
                        }
                    }
                )
            if (order.data.error === 0) {
                toast.success(`Objednávka (${order.data.orderId}) vytvořena`)
                setPaytime(false)
                setPrice(0)
                getProducts()
                setPaytimeProgress('nothing')
            } else {
                toast.error(`Při tvorbě objednávky nastala chyba`)
                setPaytimeProgress('nothing')
            }
        } catch (err) {
            console.log(err);
            toast.error(`Při tvorbě objednávky nastala chyba`)
            setPaytimeProgress('nothing')
        }
    }

    useEffect(() => {
        const foo = async () => {
            await getProducts()
            countUp()
        }
        foo()
    }, [])

    return (
        <>
            {paytime ?
                <Topmenu addItem={increase} searchText={searchText} setSearchText={setSearchText} />:
                <Topmenu searchBar addItem={increase} searchText={searchText} setSearchText={setSearchText} />
            }
            {status === 'fetching' ?
                <Loader />:
                <Items products={showProducts} categories={categories} count={count} openKeypad={openKeypad} searchText={searchText} update={[update, setUpdate]} />
            }
            {keypad && <Keypad setProductAmount={setProductAmount} openKeypad={openKeypad} keypadProduct={keypadProduct} />}
            <Bottommenu price={price} startPayment={startPayment} />
            <Summary count={count} products={products} price={price} startPayment={startPayment} />
            {paytime && <Paytime paytime={paytime} setPaytime={setPaytime} price={price} newOrder={newOrder} paytimeProgress={paytimeProgress} />}
        </>
    )
}

export default Buffet