import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSave, faXmark } from '@fortawesome/pro-light-svg-icons'
import { toast } from 'react-toastify'
import { Buffer } from 'buffer'

const StockForm = ({ categories, addProduct, editProduct, checkEanCode, productEdit }) => {
    const userBase = localStorage.getItem('user')
    const area = Buffer.from(userBase, 'base64').toString('utf-8').split(':')[1].split('-')[1]
    const [id, setId] = useState(0)
    const [name, setName] = useState('')
    const [eanCode, setEanCode] = useState('')
    const [price, setPrice] = useState('')
    const [amount, setAmount] = useState('')
    const [categoryValue, setCategoryValue] = useState('')
    const [editMode, setEditMode] = useState(false)

    const sendRequest = (e) => {
        e.preventDefault()
        var product = {
            name: name,
            eanCode: parseInt(eanCode),
            price: parseInt(price),
            amount: parseInt(amount),
            category: categoryValue,
            area: area
        }
        if (!checkEanCode(product)) {
            if (!editMode) {
                addProduct(product)
                clear()
            } else {
                productEdit(product, id)
                clear()
                setEditMode(false)
            }
        } else {
            toast.error('Produkt s tímto EAN kódem již existuje')
        }
    }

    const clear = () => {
        setName('')
        setEanCode('')
        setPrice('')
        setAmount('')
        setCategoryValue('empty')
    }

    useEffect(() => {
        if (editProduct !== '') {
            setId(editProduct.id)
            setName(editProduct.name)
            setEanCode(editProduct.eanCode)
            setPrice(editProduct.price)
            setAmount(editProduct.amount)
            setCategoryValue(editProduct.category)
            setEditMode(true)
        }
    }, [editProduct])

    return (
        <Col className='d-flex justify-content-center align-items-center mt-3 stock-form'>
            <form className='form' autoComplete='off' onSubmit={sendRequest}>
                <h3>Přidat produkt</h3>
                <div className='form-group'>
                    <label htmlFor='name'>Název produktu:</label>
                    <input type='text' id='name' name='name' value={name} onChange={(e) => setName(e.target.value)} placeholder='Název produktu' required />
                </div>
                <div className='form-group'>
                    <label htmlFor='price'>Cena produktu (Kč):</label>
                    <input type='number' id='price' name='price' value={price} min='1' onChange={(e) => setPrice(e.target.value)} placeholder='Cena produktu' required />
                </div>
                <div className='form-group'>
                    <label htmlFor='amount'>Množství:</label>
                    <input type='number' id='amount' name='amount' value={amount} min='0' onChange={(e) => setAmount(e.target.value)} placeholder='Množství' required />
                </div>
                <div className='form-group'>
                    <label htmlFor='category'>Kategorie produktu:</label>
                    <select name='category' id='category' defaultValue='empty' className='form-select' onChange={(e) => setCategoryValue(e.target.value)} required >
                        <option value="empty" hidden>Vyber kategorii</option>
                        {categories.map(category => {
                            if (categoryValue === category.name) {
                                return (
                                    <option value={category.name} id={category.name} key={category.name} selected>{category.text}</option>
                                )
                            }
                            return (
                                <option value={category.name} id={category.name} key={category.name}>{category.text}</option>
                            )
                        })}
                    </select>
                </div>
                <div className='form-group'>
                    <label htmlFor='ean-code'>EAN kód produktu:</label>
                    <input type='number' id='ean-code' name='ean-code' value={eanCode} onChange={(e) => setEanCode(e.target.value)} placeholder='EAN kód produktu' required />
                </div>
                {!editMode ?
                    <button className='btn btn-submit' type='submit'>
                        <FontAwesomeIcon icon={faPlus} />
                        Přidat produkt
                    </button>:
                    <>
                        <button className='btn btn-submit' type='submit'>
                            <FontAwesomeIcon icon={faSave} />
                            Upravit produkt
                        </button>
                        <button className='btn btn-cancel' type='reset' onClick={() => {setEditMode(false); clear()}}>
                            <FontAwesomeIcon icon={faXmark} />
                            Zrušit změny
                        </button>
                    </>
                }
            </form>
        </Col>
    )
}

export default StockForm