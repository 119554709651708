import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faMagnifyingGlass, faUser, faXmark } from '@fortawesome/pro-light-svg-icons'
import CryptoJS from 'crypto-js'
import { Buffer } from 'buffer'
import axios from 'axios'
import Cookies from 'js-cookie'

const Topmenu = ({searchBar, addItem, searchText, setSearchText, nogradient, userProp}) => {
    const [user, setUser] = useState(userProp ? userProp : {})
    const cookieUser = Cookies.get('user')
    const userBase = CryptoJS.AES.decrypt(cookieUser,  process.env.REACT_APP_CRYPT_TOKEN).toString(CryptoJS.enc.Utf8)
    const userId = Buffer.from(userBase, 'base64').toString('utf-8').split('-')[0]

    const getUser = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'users',
                    type: 'id',
                    specific: parseInt(userId)
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            setUser(response.data[0])
        } catch (error) {
            console.log(error);
        }
    }

    const menu = () => {
        var elem = document.querySelector('nav.menu');
        if (elem.classList.contains('open')) {
            elem.classList.remove('open')
        } else {
            elem.classList.add('open')
        }
    }

    const search = (e) => {
        e.preventDefault()
        if (!isNaN(searchText)) {
            addItem(parseInt(searchText), true)
            setSearchText('')
        } else {
            // Find products with this name and show them
        }
    }

    const keyDown = (event) => {
        var input = document.querySelector('#searchBar')
        var code = event.keyCode
        if (input && input !== document.activeElement) {
            if (code === 32 || (48 <= code && code <= 90) || (96 <= code && code <= 105) || code === 8) {
                input.focus()
            }
        }
    }

    document.addEventListener('keydown', keyDown)

    useEffect(() => {
      getUser()
    }, [])

    useEffect(() => {
      getUser()
    }, [userProp])

    return (
        <div className={nogradient ? "header" : "header gradien-down"}>
            <div className="menu">
                <button type="button" className="btn" onClick={menu}>
                    <FontAwesomeIcon icon={faBars} />
                </button>
            </div>
            {searchBar &&
                <form className="search" onSubmitCapture={search}>
                    <input type="text" name="search" id="searchBar" placeholder="Napiš jméno produktu, nebo naskenuj EAN..."
                        autoComplete='off' onChange={(e) => setSearchText(e.target.value)} value={searchText} />
                    <button type="submit" className="btn" onClick={search}>
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </button>
                    {searchText !== '' &&
                        <button type="button" className="btn close" onClick={() => setSearchText('')}>
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    }
                </form>
            }
            <div className="account">
                <Link to='/uzivatel' className="btn">
                    <img src={!user.imgUrl || user.imgUrl === '' ? process.env.REACT_APP_API_URL + '/resource/bufet/users/default.png' : process.env.REACT_APP_API_URL + '/resource/bufet/users/' + user.imgUrl} className='user-image' />
                </Link>
            </div>
        </div>
    )
}

export default Topmenu