import React, { useState, createContext } from 'react'
import { Topmenu, SettingsList } from '..'
import { useNavigate } from 'react-router-dom'
import { Buffer } from 'buffer'

export const AppContext = createContext(null)

const Settings = () => {
    const [component, setComponent] = useState(<SettingsList />)
    const navigate = useNavigate()
    const userBase = localStorage.getItem('user')
    const permission = Buffer.from(userBase, 'base64').toString('utf-8').split(':')[2]

    if (parseInt(permission) < 4) {
        return navigate('/unauthorized')
    }

    return (
        <>
            <Topmenu nogradient />
            <div className="main settings">
                <AppContext.Provider value={{setComponent: setComponent}} >
                    {component}
                </AppContext.Provider>
            </div>
        </>
    )
}

export default Settings