import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons'

const Summary = (props) => {
    const product = props.product
    const count = props.count

    return (
        <li className="item">
            <p className="item-name">{product.name}</p>
            <div>
                <p className="item-price"><b>{product.price * product.currentAmount} Kč</b>/{product.price} Kč</p>
                <div className="item-props">
                    <button type="button" className="btn" onClick={() => {count(product.eanCode, false)}}>
                        <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <p className="item-amout">{product.currentAmount}</p>
                    <button type="button" className="btn" onClick={() => {count(product.eanCode, true)}}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
            </div>
        </li>
    )
}

export default Summary