import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faMoneyBillWave, faCreditCard } from '@fortawesome/pro-light-svg-icons'

const Bottommenu = ({ price, startPayment }) => {
    const pay = () => {
        var elem = document.querySelector('div.paysum');
        if (elem.classList.contains('open')) {
            elem.classList.remove('open')
        } else {
            elem.classList.add('open')
        }
    }

    return (
        <div className="footer  gradien-up">
            <div className="countup">
                <div>
                    <p>Celkem za položky</p>
                    <p className="price">{price} Kč</p>
                </div>
                <button type="button" className="btn" onClick={pay}>
                    <FontAwesomeIcon icon={faAngleUp} />
                </button>
            </div>
            <div className="payment">
                <button className="btn method" onClick={startPayment}>
                    <FontAwesomeIcon icon={faMoneyBillWave} />
                    Zaplatit hotově
                </button>
            </div>
        </div>
    )
}

export default Bottommenu