import React, { useContext } from 'react'
import { Col } from 'react-bootstrap'
import { UserContext } from './Users'
import { Loader } from '../loaders'

const UsersList = () => {
    const { users } = useContext(UserContext)

    if (users.lenght < 1) {
        return <Loader />
    }

    return (
        <Col className='users'>
            <ul className="users__list">
                {users?.map(user => {
                    return <li className='users__user' key={user.id}>{user.fullname}</li>
                })}
            </ul>
        </Col>
    )
}

export default UsersList