import React, { useState, useEffect } from 'react'
import './styles/App.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';

import { App as Application, Login } from './components'

const App = () => {
	const [dark, setDark] = useState(false)
	const [user, setUser] = useState(Cookies.get('user'))

	const checkTheme = () => {
		var theme = localStorage.getItem('theme')
		var elem = document.querySelector('body')
		if (theme) {
			if (theme === 'dark') {
				setDark(true)
				elem.classList.add('dark')
			}
		} else {
			localStorage.setItem('theme', 'light')
		}
	}

	const themeSwitcher = () => {
		var theme = localStorage.getItem('theme')
		var elem = document.querySelector('body')
		if (theme !== 'light') {
			localStorage.setItem('theme', 'light')
			elem.classList.remove('dark')
			setDark(false)
		} else {
			localStorage.setItem('theme', 'dark')
			elem.classList.add('dark')
			setDark(true)
		}
	}

	const updateUser = () => {
		setUser(Cookies.get('user'))
	}

	useEffect(() => {
		checkTheme()
	}, [])


	return (
		<>
			{user ?
				<Application theme={dark} themeSwitcher={themeSwitcher} />:
				<Login updateUser={updateUser} />
			}
		</>
	)
}

export default App