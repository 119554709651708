import React, { useState, useEffect } from 'react'
import { Topmenu } from '../'
import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'
import { Buffer } from 'buffer'
import axios from 'axios'
import { toast } from 'react-toastify'

const User = () => {
    const [user, setUser] = useState({})
    const [passwords, setPasswords] = useState({
        old: '',
        new: '',
        repeatNew: ''
    })
    const [photo, setPhoto] = useState(null)
    const [update, setUpdate] = useState(true)
    const [preview, setPreview] = useState(null)
    const [areas, setAreas] = useState([])
    const cookieUser = Cookies.get('user')
    const userBase = CryptoJS.AES.decrypt(cookieUser, process.env.REACT_APP_CRYPT_TOKEN).toString(CryptoJS.enc.Utf8)
    const userId = Buffer.from(userBase, 'base64').toString('utf-8').split('-')[0]

    const getUser = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'users',
                    type: 'id',
                    specific: parseInt(userId)
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const responseAreas = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'areas'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            setAreas(responseAreas.data)
            setUser(response.data[0])
        } catch (error) {
            console.log(error);
        }
    }

    const uploadPhoto = async () => {
        const formData = new FormData()
        formData.append('file', photo)
        formData.append('userId', user.id)
        if (photo === '') {
            return toast.error('Nevybrán žádný soubor')
        }
        if (photo.size > 5000000) {
            return toast.error('Fotka je větší než 5MB')
        }
        if (photo.type === 'image/jpeg' || photo.type === 'image/jpg' || photo.type === 'image/png') {
            try {
                await axios.post(process.env.REACT_APP_API_URL + '/bufet/user',
                    formData,
                    {
                        params: {
                            database: 'bufet',
                            table: 'users',
                        },
                        headers: {
                            Authorization: process.env.REACT_APP_API_TOKEN,
                            'content-type': 'multipart/form-data'
                        }
                })
                toast.success('Obrázek nahrán')
                setPhoto(null)
                getUser()
            } catch (error) {
                console.log(error);
                toast.error('Chyba při nahrávání obrázku')
            }
        } else {
            return toast.error('Soubor není fotka')
        }
    }

    useEffect(() => {
        getUser()
    }, [])

    useEffect(() => {
        if (photo) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setPreview(reader.result)
            }
            reader.readAsDataURL(photo)
        } else {
            setPreview(null)
        }
    }, [photo])

    const changePasses = (e, type) => {
        switch (type) {
            case 'old':
                passwords.old = e.target.value
                break
            case 'new':
                passwords.new = e.target.value
                break
            case 'repeatNew':
                passwords.repeatNew = e.target.value
                break
        }
        setUpdate(!update)
    }

    const changePassword = async () => {
        if (passwords.old === '' || passwords.new === '' || passwords.repeatNew === '') {
            return toast.error('Všechna pole musí být vyplněna')
        } else if (passwords.new !== passwords.repeatNew) {
            return toast.error('Nová hesla se neshodují')
        }
        try {
            return toast.info('Tato funkce ještě není dostupná')
        } catch (error) {
            console.log(error);
        }
    }

    const changeArea = async () => {
        const dataJSON = JSON.stringify(user)
        try {
            await axios.patch(process.env.REACT_APP_API_URL + '/site', {
                data: dataJSON,
                specific: {
                    type: 'id',
                    specific: user.id
                }
            },
            {
                params: {
                    database: 'bufet',
                    table: 'users',
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            toast.success('Areál změněn')
        } catch (error) {
            console.log(error);
        }
    }

    const getArea = () => {
        const returnArea = areas.find(area => (area.id === user.area))
        return returnArea?.name
    }

    return (
        <>
            <Topmenu nogradient userProp={user} />
            <div className="main user">
                <div className="user__name">
                    <h2>Základní informace</h2>
                    <h4 className="user__fullname">{user.fullname}</h4>
                    <p className='user__username'>Přihlašovací jméno: <b>{user.name}</b></p>
                    <p className='user__area'>Areál: <b>{getArea()}</b></p>
                </div>
                <div className="user__photo">
                    <h2>Fotografie</h2>
                    {preview && <img src={preview} className='user__preview' />}
                    <input type="file" files={photo} onChange={(e) => {setPhoto(e.target.files[0])}} />
                    <button className="btn btn-submit" onClick={uploadPhoto}>Nahrát</button>
                </div>
                <div className="user__password form">
                    <h2>Heslo</h2>
                    <div className="form-group">
                        <input type="password" value={passwords.old} placeholder='Staré heslo' onChange={(e) => changePasses(e, 'old')} required />
                    </div>
                    <div className="form-group">
                        <input type="password" value={passwords.new} placeholder='Nové heslo' onChange={(e) => changePasses(e, 'new')} required />
                    </div>
                    <div className="form-group">
                        <input type="password" value={passwords.repeatNew} placeholder='Znovu nové heslo' onChange={(e) => changePasses(e, 'repeatNew')} required />
                    </div>
                    <button type='submit' className='btn btn-submit' onClick={changePassword}>Změnit heslo</button>
                </div>
                {user.permisions > 3 &&
                    <div className="user__areas form">
                        <h2>Areál</h2>
                        <div className="form-group">
                            <select defaultValue={user.area} onChange={(e) => {user.area = parseInt(e.target.value)}} className="form-select">
                                {areas.map(area => {
                                    return <option value={area.id} key={area.id}>{area.name}</option>
                                })}
                            </select>
                        </div>
                        <button className="btn btn-submit" onClick={changeArea}>
                            Uložit
                        </button>
                    </div>
                }
            </div>
        </>
    )
}

export default User