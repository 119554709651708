import React, { useContext } from 'react'
import { Col } from 'react-bootstrap'
import { overviewContext } from './Overview'

const OverviewProduct = ({ product }) => {
    const { products, defaultValue, orders } = useContext(overviewContext)
    const getProduct = (id) => {
        return defaultValue.find(value => { return value.productId === id })
    }

    let sumSales = 0
    let defaultAmount = getProduct(product.id) ? getProduct(product.id).amount + 'ks' : '━━'

    orders.map(order => {
        order.products.map(orderProduct => {
            if (orderProduct.productId === product.id && product.orders.includes(orderProduct.orderId)) {
                sumSales += orderProduct.amount
            }
        })
    })

    return (
        <Col xs={6} xl={2} md={4}  >
            <div className="overview__product-content">
                <p className="product-name"><b>{product.name}</b></p>
                <p className="product-price">Cena: <b>{product.price}Kč</b></p>
                <p className={defaultAmount >= sumSales + product.amount && defaultAmount !== '━━' ? "product-stock red" : "product-stock green"}>Sklad: <b>{product.amount}ks</b></p>
                <p className="product-default">Výchozí stav: <b>{defaultAmount}</b></p>
                <p className="product-sold">Prodáno: <b>{sumSales}ks</b></p>
                <p className="product-earns">Výdělek: <b>{sumSales * product.price}Kč</b></p>
            </div>
        </Col>
    )
}

export default OverviewProduct