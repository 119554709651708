import React from 'react'
import { Row } from 'react-bootstrap'
import { Item } from '../'

const Items = ({ products, categories, count, openKeypad, searchText }) => {
    return (
        <div className='main'>
            {categories.map(category => {
                if (category.products > 0) {
                    return (
                        <section className='category' key={category.name} >
                            <h2>{category.text}</h2>
                            <Row className='row-cols-6 gx-3 gy-3'>
                                    {products.map(product => {
                                        if (category.name === product.category && product.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) {
                                            return (<Item product={product} key={product.eanCode} count={count} openKeypad={openKeypad} />)
                                        }
                                    })}
                            </Row>
                        </section>
                    )
                }
            })}
        </div>
    )
}

export default Items