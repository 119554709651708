import React, { useContext } from 'react'
import { AppContext } from './Settings';
import { Turnuses, Users } from './'

const SettingsList = () => {
    const { setComponent } = useContext(AppContext)

    return (
        <>
            <button className='btn' onClick={() => {setComponent(<Turnuses />)}}>Turnusy</button>
            <button className='btn' onClick={() => {setComponent(<Users />)}}>Uživatelé</button>
        </>
    )
}

export default SettingsList