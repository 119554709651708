import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faXmark } from '@fortawesome/pro-light-svg-icons'
import { OrderDetailProduct } from '..'
import axios from 'axios'
import { toast } from 'react-toastify'

const Order = ({ order, products, getOrders }) => {
    const date= new Date(order.date)
    const [detail, setDetail] = useState(false)
    var orderProducts = []
    const zeroCheck = (num) => {
        return num < 10 ? "0" + num : num
    }

    const deleteOrder = async (id) => {
        try {
            const response = await axios.delete(process.env.REACT_APP_API_URL + '/bufet/order', {
                data: {
                    type: 'id',
                    specific: id,
                    order: order
                },
                params: {
                    database: 'bufet',
                    table: 'products'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            getOrders()
            return toast.success(`Objednávka ${order.orderId} odstraněna`)
        } catch (error) {
            console.log(error)
            return toast.error(`Mazání objednávky se ${order.orderId} nezdařilo`)
        }
    }

    return (
        <li className='order'>
            <div className="order__info">
                <div>
                    <h3 className="order__heading">Obj.: <span>{order.orderId}</span></h3>
                    <p className='order__price'>{order.price}Kč</p>
                    <p className="order__date">
                        {date.getDate()}. {date.getMonth() + 1}. {date.getFullYear()} - {zeroCheck(date.getHours())}:{zeroCheck(date.getMinutes())}
                    </p>
                </div>
                <div className='order__options'>
                    <button type='button' className='btn btn-show' onClick={() => {setDetail(!detail)}}>
                        <FontAwesomeIcon icon={detail ? faEyeSlash : faEye} />
                    </button>
                    <button type='button' className='btn btn-danger' onClick={() => {deleteOrder(order.id)}}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>
            </div>
            {detail && <ul className='order__products'>
                {order.products.map(orderProduct => {
                    return (
                        products.map(product => {
                            if (orderProduct.productId === product.id) {
                                return <OrderDetailProduct product={product} orderProduct={orderProduct} key={product.id} />
                            }
                        })
                    )
                })}
            </ul>}
        </li>
    )
}

export default Order