import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Topmenu, Itemlist, StockForm } from '../'
import { Loader } from '../loaders'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Buffer } from 'buffer'

const Stock = () => {
    const navigate = useNavigate()
    const [products, setProducts] = useState([])
    const [categories, setCategories] = useState([])
    const [update, setUpdate] = useState(true)
    const [status, setStatus] = useState('fetching')
    const [editProduct, setEditProduct] = useState('')
    const [defaultValue, setDefaultValue] = useState([])
    const userBase = localStorage.getItem('user')
    const turnusBase = localStorage.getItem('turnus')
    const area = Buffer.from(userBase, 'base64').toString('utf-8').split(':')[1].split('-')[1]
    const permission = Buffer.from(userBase, 'base64').toString('utf-8').split(':')[2]
    const turnusId = parseInt(Buffer.from(turnusBase, 'base64').toString('utf-8').split(':')[0])

    const getProducts = async () => {
        try {
            const responseProducts = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'products',
                    type: 'area',
                    specific: area
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const responseCategories = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'categories'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const defaultValues = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'defaultValues'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            responseProducts.data.map(product => {
                product.currentAmount = 0
            })
            responseCategories.data.map(category => {
                category.products = 0
            })
            for (const category of responseCategories.data) {
                for (const product of responseProducts.data) {
                    if (category.name === product.category) {
                        category.products++
                    }
                }
            }
            setProducts(responseProducts.data)
            setCategories(responseCategories.data)
            setDefaultValue(defaultValues.data)
            setStatus('fetched')
        } catch (err) {
            console.log(err);
        }
    }

    const checkEanCode = (currentProduct) => {
        var n = 0
        for(var product of products) {
            if (product.eanCode === currentProduct.eanCode) {
                n++
            }
        }
        return n > 1
    }

    const addToProducts = async (product) => {
        var productJSON = JSON.stringify(product)
        try {
            await axios.post(process.env.REACT_APP_API_URL + '/site', {
                    data: productJSON
                },
                {
                    params: {
                        database: 'bufet',
                        table: 'products',
                    },
                    headers: {
                        Authorization: process.env.REACT_APP_API_TOKEN
                    }
                }
            )
            setProducts(products => ([...products, product]))
            setUpdate(!update)
            toast.success(`Produkt ${product.name} přidán`)
        } catch (err) {
            console.log(err);
            toast.error(`Při přidávání produktu ${product.name} nastala chyba`)
        }
    }

    const productEdit = async (product, id) => {
        var productJSON = JSON.stringify(product)
        try {
            await axios.patch(process.env.REACT_APP_API_URL + '/site', {
                    data: productJSON,
                    specific: {
                        type: 'id',
                        specific: id
                    }
                },
                {
                    params: {
                        database: 'bufet',
                        table: 'products',
                    },
                    headers: {
                        Authorization: process.env.REACT_APP_API_TOKEN
                    }
                }
            )
            getProducts()
            toast.success(`Produkt ${product.name} změněn`)
        } catch (err) {
            console.log(err);
            toast.error(`Při úpravě produktu ${product.name} nastala chyba`)
        }
    }

    useEffect(() => {
        if (parseInt(permission) < 2) {
            return navigate('/unauthorized')
        }
        const foo = async () => {
            await getProducts()
        }
        foo()
    }, [])

    return (
        <>
            <Topmenu />
            <div className='main stock'>
                <Row className='rows-col-2'>
                    {status === 'fetching' ?
                        <Col><Loader /></Col> :
                        <Itemlist products={products} categories={categories} getProducts={getProducts} setEditProduct={setEditProduct} defaultValue={defaultValue} />
                    }
                    <StockForm categories={categories} addProduct={addToProducts} editProduct={editProduct} checkEanCode={checkEanCode} productEdit={productEdit} />
                </Row>
            </div>
        </>
    )
}

export default Stock