import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons'
import { Row, Col } from 'react-bootstrap'

const Item = ({ product, count, openKeypad }) => {
    const zeroCheck = (event, amount) => {
        if (amount < 1) {
            var elem = event.target
            while (!elem.classList.contains('item')) {
                elem = elem.parentNode
            }
            elem.classList.add('shake')
            setTimeout(() => {
                elem.classList.remove('shake')
            }, 820)
        }
    }

    const checkClick = (event, product) => {
        var list = ['button', 'svg', 'path']
        var name = event.target.localName
        if (!list.includes(name) && product.amount !== 0) {
            openKeypad(true, product)
        }
    }

    return (
        <Col md={4} xl={2} xs={6}>
            <div className={product.amount === 0 ? 'item disabled' : 'item'} onClick={(e) => checkClick(e, product)}>
                <div className='item-info'>
                    <p className='item-name'>{product.name}</p>
                    <p className='item-price'>{product.price} Kč</p>
                </div>
                <div className='item-props'>
                    <button type='button' disabled={product.amount === 0} className='btn btn-decrease' onClick={(e) => {count(product.eanCode, false); zeroCheck(e, product.currentAmount)}}>
                        <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <p className='item-amout'>{product.currentAmount}</p>
                    <button type='button' disabled={product.amount === 0} className='btn btn-increase' onClick={() => {count(product.eanCode, true)}}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
            </div>
        </Col>
    )
}

export default Item