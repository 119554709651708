import React, { useState } from 'react'
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom'
import { LoginForm, PasswordReset, Unauthorized, ErrorSite } from '../'
import axios from 'axios'
import { Buffer } from 'buffer'
import Cookies from 'js-cookie'
import { toast, ToastContainer } from 'react-toastify'

const Login = ({ updateUser }) => {
    const [logining, setLogining] = useState(false)
    const navigate = useNavigate()

    const login = async (name, password) => {
        setLogining(true)
        const credentialsBasic = name + ':' + password
        const credentials = Buffer.from(credentialsBasic)
        const dataJSON = JSON.stringify({ credentials: credentials })
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + "/bufet/login",
                {
                    data: dataJSON,
                },
                {
                    params: {
                        database: 'bufet',
                        table: 'users',
                    },
                    headers: {
                        Authorization: process.env.REACT_APP_API_TOKEN,
                        'Access-Control-Allow-Origin': '*'
                    }
                }
            )
            if(!response.data.logged) {
                setLogining(false)
                return toast.error('Špatné přihlašovací údaje')
            }
            Cookies.set('user', response.data.user, { expires: 7 })
            updateUser()
            setLogining(false)
        } catch (error) {
            console.log(error);
            setLogining(false)
        }
    }

    const resetPass = async (name, password) => {
        const credentialsBasic = name + ':' + password
        const credentials = Buffer.from(credentialsBasic)
        const dataJSON = JSON.stringify({ credentials: credentials })
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + "/bufet/resetPassword",
                {
                    data: dataJSON,
                },
                {
                    params: {
                        database: 'bufet',
                        table: 'users',
                    },
                    headers: {
                        Authorization: process.env.REACT_APP_API_TOKEN
                    }
                }
            )
            if (response.data.changed) {
                toast.success('Heslo změněno')
                return navigate('/')
            }
            toast.error('Uživatel nenalezen nebo tuto akci nelze provést')
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Routes>
                <Route exact path='/' element={<LoginForm login={login} logining={logining} />} />
                <Route exact path='/reset' element={<PasswordReset resetPass={resetPass} />} />
                <Route path='*' element={<Navigate to='/' />} />
            </Routes>
            <ToastContainer limit={5} />
        </>
    )
}

export default Login