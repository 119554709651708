import React, { useState, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { PayKeypad } from '../'
import { ProgressLoader } from '../loaders'
import Modal from 'react-modal'

const Paytime = ({ paytime, setPaytime, price, newOrder, paytimeProgress }) => {
    const [number, setNumber] = useState(0)

    const keyDown = useCallback(event => {
        var { keyCode, key } = event
        if (96 <= keyCode && keyCode <= 105) {
            setNumber(number * 10 + parseInt(key))
        } else if (keyCode === 8) {
            setNumber(parseInt(number / 10))
        }
    })

    useEffect(() => {
      window.addEventListener('keydown', keyDown)
      return () => {
        window.removeEventListener('keydown', keyDown)
      }
    }, [keyDown])

    return (
        <div className={paytime ? 'paytime open' : 'paytime'}>
            <button className='btn btn-decrease' onClick={() => {setPaytime(false)}}>
                <FontAwesomeIcon icon={faArrowLeft} />
                Zpět
            </button>
            <div className="content">
                <h1>K zaplacení: {price - number > 0 ? price : 0}Kč</h1>
                <h1>K vrácení: {price - number < 0 ? (price - number) * -1 : 0}Kč</h1>
                <PayKeypad number={number} setNumber={setNumber} />
                <button className="btn btn-submit" disabled={price - number > 0 || price <= 0} onClick={() => {newOrder(price)}} >
                    Dokončit platbu
                </button>
            </div>
            <Modal isOpen={paytimeProgress === 'loading'} ariaHideApp={false}>
                <ProgressLoader />
            </Modal>
        </div>
    )
}

export default Paytime