import React, { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'
import { Offline, Online } from 'react-online-status'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifi, faWifiSlash } from '@fortawesome/pro-light-svg-icons'
import { Loader } from './loaders'
import axios from 'axios'
import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'
import { Buffer } from 'buffer'

import { Menu, Home, Buffet, ErrorSite, Unauthorized, Stock, Orders, Settings, Login, User, Overview } from './'

const App = ({ theme, themeSwitcher }) => {
  const [loading, setLoading] = useState(true)
  const getTurnuses = async () => {
    const cookieUser = Cookies.get('user')
    const userBase = CryptoJS.AES.decrypt(cookieUser, process.env.REACT_APP_CRYPT_TOKEN).toString(CryptoJS.enc.Utf8)
    const userId = Buffer.from(userBase, 'base64').toString('utf-8').split('-')[0]
    const today = new Date()
    try {
      const responseUser = await axios.get(process.env.REACT_APP_API_URL + '/site', {
        params: {
          database: 'bufet',
          table: 'users',
          type: 'id',
          specific: parseInt(userId)
        },
        headers: {
          Authorization: process.env.REACT_APP_API_TOKEN
        }
      })
      const user = responseUser.data[0]
      localStorage.setItem('user', Buffer.from(user.name + ':area-' + user.area + ':' + user.permisions, 'utf-8').toString('base64'))
      const response = await axios.get(process.env.REACT_APP_API_URL + '/site', {
        params: {
          database: 'bufet',
          table: 'turnuses',
          type: 'area',
          specific: parseInt(user.area)
        },
        headers: {
          Authorization: process.env.REACT_APP_API_TOKEN
        }
      })
      const turnuses = response.data
      var saveStatus = false
      for (const turnus of turnuses) {
        if (today >= turnus.dateFrom && today <= turnus.dateTo) {
          var turnusData = Buffer.from(`${turnus.id}:${turnus.dateFrom}-${turnus.dateTo}:on`, 'utf-8').toString('base64')
          localStorage.setItem('turnus', turnusData)
          saveStatus = true
          break
        }
      }
      if (!saveStatus) {
        if (turnuses.length > 0) {
          const lastTurnus = turnuses.sort((a, b) => (a.dateTo > b.dateTo) ? 1 : -1)[turnuses.length - 1]
          var turnusData = Buffer.from(`${lastTurnus.id}:${lastTurnus.dateFrom}-${lastTurnus.dateTo}:off`, 'utf-8').toString('base64')
          localStorage.setItem('turnus', turnusData)
        } else {
          var turnusData = Buffer.from(`null:null-null:off`, 'utf-8').toString('base64')
          localStorage.setItem('turnus', turnusData)
        }
    }
      setLoading(false)
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getTurnuses()
  }, [])

  if(loading) {
    return <Loader height />
  }

  return (
    <>
      <Menu theme={theme} themeSwitcher={themeSwitcher} />
      <Container>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/bufet' element={<Buffet />} />
          <Route path='/sklad' element={<Stock />} />
          <Route path='/objednavky' element={<Orders />} />
          <Route path='/uzivatel' element={<User />} />
          <Route path='/nastaveni' element={<Settings />} />
          <Route path='/prehledy' element={<Overview />} />
          <Route path='/unauthorized' element={<Unauthorized />} />
          <Route path='*' element={<ErrorSite />} />
        </Routes>
      </Container>
      <Offline>
        <div className="status-msg offline">
          <FontAwesomeIcon icon={faWifiSlash} />
          <p>Nejsi připojený k internetu</p>
        </div>
      </Offline>
      <Online>
        <div className='status-msg online'>
          <FontAwesomeIcon icon={faWifi} />
          <p>Připojení obnoveno</p>
        </div>
      </Online>
      <ToastContainer limit={5} autoClose={3000} />
    </>
  )
}

export default App