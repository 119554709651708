import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'

const Loader = ({ height }) => {
    return (
        <div className={height ? 'loader h-100' : 'loader'} >
            <FontAwesomeIcon icon={faSpinnerThird} />
        </div>
    )
}

export default Loader