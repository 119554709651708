import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faMoneyBillWave, faCreditCard } from '@fortawesome/pro-light-svg-icons'
import { SummaryItem } from '../'

const Summary = ({ price, products, count, startPayment }) => {
    const pay = () => {
        var elem = document.querySelector('div.paysum');
        if (elem.classList.contains('open')) {
            elem.classList.remove('open')
        } else {
            elem.classList.add('open')
        }
    }

    return (
        <div className="paysum">
            <div className="summary">
                <h2>Shrnutí objednávky</h2>
                <div className="list">
                    <ul className="itemlist">
                        {products.map(product => {
                            if (product.currentAmount > 0) {
                                return <SummaryItem product={product} count={count} key={product.eanCode} />
                            }
                        })}
                    </ul>
                    <div className="countup">
                        <button type="button" className="btn" onClick={pay}>
                            <FontAwesomeIcon icon={faAngleDown} />
                        </button>
                        <div>
                            <p>Celkem za položky</p>
                            <p className="price">{price} Kč</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="methods">
                <button className="btn method" type="button" onClick={startPayment}>
                    <FontAwesomeIcon icon={faMoneyBillWave} />
                    Zaplatit hotově
                </button>
            </div>
        </div>
    )
}

export default Summary