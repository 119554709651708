import React from 'react'
import Topmenu from './menus/Topmenu'

const Unauthorized = () => {
    return (
        <>
            <Topmenu />
            <div className="error-site">
                <h1>Chyba 401</h1>
                <p>Nedostatečná oprávnění</p>
            </div>
        </>
    )
}

export default Unauthorized