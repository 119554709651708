import React, { useContext } from 'react'
import { overviewContext } from './Overview'
import { OverviewProduct } from '../'
import { Row } from 'react-bootstrap'

const ProductList = () => {
    const { products, orders } = useContext(overviewContext)

    let sumPrice = 0
    orders.map(order => {
        order.products.map(orderProduct => {
            products.map(product => {
                if (product.id === orderProduct.productId) {
                    sumPrice += product.price * orderProduct.amount
                }
            })
        })
    })

    return(
        <>
            <p className='overall-sale-sum'>Celokový výdělek: <b>{sumPrice}Kč</b></p>
            <Row className='overview__products-tab'>
                {products.map(product => {
                    return <OverviewProduct product={product} key={product.id} />
                })}
            </Row>
        </>
    )

    // return (
    //     <table>
    //         <thead>
    //             <tr>
    //                 <th>Název produktu</th>
    //                 <th>Počet na začátku turnusu</th>
    //                 <th>Prodáno</th>
    //                 <th>Cena prodaného zboží</th>
    //                 <th>Momentální stav skladu</th>
    //             </tr>
    //         </thead>
    //         <tbody>
    //             {products.map(product => {
    //                 return <OverviewProduct product={product} key={product.id} />
    //             })}
    //         </tbody>
    //         <tfoot>
    //             <tr>
    //                 <td colSpan={2}></td>
    //                 <td>Celkové cena</td>
    //                 <td><b>{sumPrice}Kč</b></td>
    //                 <td></td>
    //             </tr>
    //         </tfoot>
    //     </table>
    // )
}

export default ProductList