import React, { useState, useEffect, createContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { Topmenu, ProductList } from '../'
import { Loader } from '../loaders';
import axios from 'axios'
import { Buffer } from 'buffer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateRight } from '@fortawesome/pro-light-svg-icons'
import { toast } from 'react-toastify';

export const overviewContext = createContext(null)

const Overview = () => {
    const dateBase = localStorage.getItem('turnus')
    const userBase = localStorage.getItem('user')
    const navigate = useNavigate()
    const [products, setProducts] = useState([])
    const [orders, setOrders] = useState([])
    const [turnus, setTurnus] = useState({})
    const [areaInfo, setAreaInfo] = useState({})
    const [turnuses, setTurnuses] = useState([])
    const [areas, setAreas] = useState([])
    const [defaultValue, setDefaultValue] = useState([])
    const [date, setDate] = useState(NaN)
    const [status, setStatus] = useState('fetching')
    const [areaId, setAreaId] = useState(parseInt(Buffer.from(userBase, 'base64').toString('utf-8').split(':')[1].split('-')[1]))
    const [turnusId, setTurnusId] = useState(parseInt((Buffer.from(dateBase, 'base64').toString('utf-8')).split(':')[0]))
    const permission = Buffer.from(userBase, 'base64').toString('utf-8').split(':')[2]

    const getOverviewData = async () => {
        try {
            const responseProducts = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'products',
                    type: 'area',
                    specific: areaId
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const turnusData = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'turnuses',
                    type: 'id',
                    specific: parseInt(turnusId)
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const responseOrders = await axios.get(process.env.REACT_APP_API_URL + '/bufet/orders', {
                params: {
                    database: 'bufet',
                    table: 'orders',
                    from: turnusData.data[0].dateFrom,
                    to: turnusData.data[0].dateTo,
                    area: areaId
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const orderProducts = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'order_products'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const areaData = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'areas',
                    type: 'id',
                    specific: parseInt(areaId)
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const areasData = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'areas',
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const turnusesData = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'turnuses'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const defaultValues = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'defaultValues',
                    type: 'turnusId',
                    specific: parseInt(turnusId)
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            responseProducts.data.map(product => {
                product.orders = []
            })
            responseOrders.data.data.map(order => {
                order.products = []
                orderProducts.data.map(product => {
                    if (order.orderId === product.orderId) {
                        order.products.push(product)
                        let orderedProduct = responseProducts.data.find(productFromOrder => { return productFromOrder.id === product.productId })
                        orderedProduct.orders.push(order.orderId)
                    }
                })
            })
            setTurnus(turnusData.data[0])
            setAreaInfo(areaData.data[0])
            setProducts(responseProducts.data)
            setOrders(responseOrders.data.data)
            setAreas(areasData.data)
            setTurnuses(turnusesData.data)
            setDefaultValue(defaultValues.data)
            setStatus('fetched')
        } catch (err) {
            console.log(err);
        }
    }

    const filterOrders = async () => {
        const responseProducts = await axios.get(process.env.REACT_APP_API_URL + '/site', {
            params: {
                database: 'bufet',
                table: 'products',
                type: 'area',
                specific: areaId
            },
            headers: {
                Authorization: process.env.REACT_APP_API_TOKEN
            }
        })
        const turnusData = await axios.get(process.env.REACT_APP_API_URL + '/site', {
            params: {
                database: 'bufet',
                table: 'turnuses',
                type: 'id',
                specific: parseInt(turnusId)
            },
            headers: {
                Authorization: process.env.REACT_APP_API_TOKEN
            }
        })
        let responseOrders
        if (!isNaN(date)) {
            responseOrders = await axios.get(process.env.REACT_APP_API_URL + '/bufet/orders', {
                params: {
                    database: 'bufet',
                    table: 'orders',
                    from: date,
                    to: date + 86340000,
                    area: parseInt(areaId)
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
        } else {
            responseOrders = await axios.get(process.env.REACT_APP_API_URL + '/bufet/orders', {
                params: {
                    database: 'bufet',
                    table: 'orders',
                    from: turnusData.data[0].dateFrom,
                    to: turnusData.data[0].dateTo,
                    area: parseInt(areaId)
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
        }
        const orderProducts = await axios.get(process.env.REACT_APP_API_URL + '/site', {
            params: {
                database: 'bufet',
                table: 'order_products'
            },
            headers: {
                Authorization: process.env.REACT_APP_API_TOKEN
            }
        })
        const areaData = await axios.get(process.env.REACT_APP_API_URL + '/site', {
            params: {
                database: 'bufet',
                table: 'areas',
                type: 'id',
                specific: parseInt(areaId)
            },
            headers: {
                Authorization: process.env.REACT_APP_API_TOKEN
            }
        })
        const defaultValues = await axios.get(process.env.REACT_APP_API_URL + '/site', {
            params: {
                database: 'bufet',
                table: 'defaultValues',
                type: 'turnusId',
                specific: parseInt(turnusId)
            },
            headers: {
                Authorization: process.env.REACT_APP_API_TOKEN
            }
        })
        responseProducts.data.map(product => {
            product.orders = []
        })
        console.log(responseProducts.data);
        responseOrders.data.data.map(order => {
            order.products = []
            console.log(order);
            orderProducts.data.map(product => {
                if (order.orderId === product.orderId) {
                    order.products.push(product)
                    let orderedProduct = responseProducts.data.find(productFromOrder => { return productFromOrder.id === product.productId })
                    orderedProduct.orders.push(order.orderId)
                }
            })
        })
        setTurnus(turnusData.data[0])
        setAreaInfo(areaData.data[0])
        setOrders(responseOrders.data.data)
        setDefaultValue(defaultValues.data)
        setProducts(responseProducts.data)
        setStatus('fetched')
    }

    const resetValues = () => {
        setStatus('fetching')
        setDate(NaN)
        setAreaId((Buffer.from(userBase, 'base64').toString('utf-8')).split(':')[1].split('-')[1])
        setTurnusId((Buffer.from(dateBase, 'base64').toString('utf-8')).split(':')[0])
    }

    const resetFilters = () => {
        resetValues()
        getOverviewData()
    }

    useEffect(() => {
        if (parseInt(permission) < 3) {
            return navigate('/unauthorized')
        }
        getOverviewData()
    }, [])

    const startFilter = () => {
        if (!isNaN(date) && !(date < turnus.dateTo && date > turnus.dateFrom)) {
            return toast.error('Den nepatří do tohoto turnusu')
        }
        setStatus('fetching')
        filterOrders()
    }

    const setCorrectTurnusId = (id) => {
        const turnusFromArea =  turnuses.find(turnus => { return turnus.area === parseInt(id) })
        setTurnusId(turnusFromArea?.id)
    }

    return (
        <>
            <Topmenu nogradient />
            {status === 'fetching' ?
                <Loader /> :
                <div className="main overview">
                    <div className="overview__header">
                        <h2>
                            Přehledy
                            <button type='button' className='btn btn-normal reload' onClick={() => {setStatus('fetching'); getOverviewData()}}>
                                <FontAwesomeIcon icon={faArrowRotateRight} />
                            </button>
                        </h2>
                        <span>{areaInfo?.name}, {turnus?.name}: {new Date(turnus?.dateFrom).getDate()}.&nbsp;
                            {new Date(turnus?.dateFrom).getMonth() + 1}.&nbsp;
                            {new Date(turnus?.dateFrom).getFullYear()}&nbsp;
                            -&nbsp;
                            {new Date(turnus?.dateTo).getDate()}.&nbsp;
                            {new Date(turnus?.dateTo).getMonth() + 1}.&nbsp;
                            {new Date(turnus?.dateTo).getFullYear()}&nbsp;</span>
                            <div className="overview__filters form">
                                {permission > 3 &&
                                    <div className="form-group">
                                        <select value={parseInt(areaId)} onChange={(e) => { setAreaId(e.target.value); setCorrectTurnusId(e.target.value) }} className="form-select">
                                            {areas?.map(area => {
                                                return <option value={area.id} key={area.id}>{area.name}</option>
                                            })}
                                        </select>
                                    </div>
                                }
                                <div className="form-group">
                                    <select value={parseInt(turnusId)} onChange={(e) => { setTurnusId(e.target.value); setDate(NaN) }} className="form-select">
                                        {turnuses?.sort((a, b) => (a.dateFrom > b.dateFrom) ? 1 : -1)?.map(turnusData => {
                                            if (turnusData.area === parseInt(areaId)) {
                                                return <option value={parseInt(turnusData.id)} key={turnusData.id} >{turnusData.name}</option>
                                            }
                                        })}
                                        {turnuses.length === 0 &&
                                            <option disabled>Žádný turnus</option>
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <input type="date" name="date" className='form-date'
                                            value={!isNaN(date) ?
                                                new Date(date).toISOString().split('T')[0]:
                                                NaN
                                            }
                                            onChange={(e) => {setDate(new Date(e.target.value).valueOf())}}
                                    />
                                </div>
                                <button className="btn btn-submit" type='button' onClick={startFilter}>Filtrovat</button>
                                <button className="btn btn-submit" type='button' onClick={resetFilters}>Zrušit filtry</button>
                            </div>
                    </div>
                    <div className="overview__body">
                        <overviewContext.Provider value={{products, orders, defaultValue, areaId}}>
                            <ProductList />
                        </overviewContext.Provider>
                    </div>
                </div>
            }
        </>
    )
}

export default Overview