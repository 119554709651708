import React, { useContext, useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import axios from 'axios'
import { UserContext } from './Users'
import { Loader } from '../loaders'
import { Buffer } from 'buffer'

const UsersForm = () => {
    const { saveUser } = useContext(UserContext)
    const [name, setName] = useState('')
    const [fullname, setFullname] = useState('')
    const [area, setArea] = useState(0)
    const [areas, setAreas] = useState([])
    const [permision, setPermision] = useState(0)
    const [password, setPassword] = useState('')

    const getAreas = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'areas'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            setAreas(response.data)
        } catch (error) {
            console.log(error);
        }
    }

    const sendRequest = (e) => {
        e.preventDefault()
        const data = {
            name,
            fullname,
            permisions: permision,
            area,
            password
        }
        saveUser(data)
    }

    useEffect(() => {
        getAreas()
    }, [])


    return (
        <Col className='users__form'>
            <form className="form" autoComplete='off'>
                <div className="form-group">
                    <label htmlFor="name">Přihlašovací jméno</label>
                    <input type="text" id="name" value={name} onChange={(e) => {setName(e.target.value)}} placeholder='Přihlašovací jméno' required />
                </div>
                <div className="form-group">
                    <label htmlFor="fullname">Celé jméno</label>
                    <input type="text" value={fullname} onChange={(e) => {setFullname(e.target.value)}} placeholder='Celé jméno' required />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Areál</label>
                    <select id="area" className="form-select" onChange={(e) => {setArea(e.target.value)}} defaultValue={area}>
                        <option value="0" disabled>Žádný</option>
                        {areas?.map(areaData => {
                            return <option value={areaData.id} key={areaData.id}>{areaData.name}</option>
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="permission">Práva</label>
                    <select id="permission" className="form-select" onChange={(e) => {setPermision(e.target.value)}} defaultValue={permision}>
                        <option value="0" disabled>Žádná</option>
                        <option value="1">Prodavač</option>
                        <option value="2">Správce</option>
                        <option value="3">Správce+</option>
                        <option value="4">Admin</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Heslo</label>
                    <input type="password" value={password} onChange={(e) => {setPassword(e.target.value)}} placeholder='Heslo' required />
                </div>
                <button className="btn btn-submit" onClick={(e) => {sendRequest(e)}}>Přidat uživatele</button>
            </form>
        </Col>
    )
}

export default UsersForm