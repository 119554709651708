import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'

const ButtonLoader = () => {
    return (
        <>
            <FontAwesomeIcon icon={faSpinnerThird} className="loader" />
        </>
    )
}

export default ButtonLoader