import React from 'react'
import { Col } from 'react-bootstrap'
import { StockItem } from '..'

const Itemlist = ({ products, categories, getProducts, setEditProduct, defaultValue }) => {
    return (
        <Col>
            {categories.map(category => {
                if (category.products > 0) {
                    return (
                        <div className="item-category" key={category.name}>
                            <h2>{category.text}</h2>
                            <ul className="itemlist">
                                {products.map(product => {
                                    if (category.name === product.category) {
                                        return (<StockItem product={product} key={product.eanCode} getProducts={getProducts} setEditProduct={setEditProduct} defaultValue={defaultValue} />)
                                    }
                                })}
                            </ul>
                        </div>
                    )
                }
            })}
        </Col>
    )
}

export default Itemlist