import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ButtonLoader } from '../loaders'

const LoginForm = ( { login, logining }) => {
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [stayLogged, setStayLogged] = useState(false)

    const sendRequest = (e) => {
        e.preventDefault()
        login(name, password)
    }

    return (
        <div className="login">
            <div className="login__header">
                <h1>Vítej v bufetové aplikaci 4CAMPS</h1>
           </div>
            <form className="form" onSubmit={sendRequest} autoComplete="off" >
                <div className="form-group">
                    <label htmlFor="name">Jméno</label>
                    <input type="text" value={name} name='name' id="name" onChange={(e) => {setName(e.target.value)}} placeholder="Jméno" required />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Heslo</label>
                    <input type="password" value={password} name='password' id="password" onChange={(e) => {setPassword(e.target.value)}} placeholder="Heslo" required />
                </div>
                <button type="submit" className="btn btn-submit">{logining ? <ButtonLoader></ButtonLoader> : 'Přihlásit se'}</button>
            </form>
            <Link to="/reset" className='reset-link'>Zapomněl jsem heslo</Link>
        </div>
    )
}

export default LoginForm