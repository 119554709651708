import React, { useState, useEffect } from 'react'
import { Topmenu } from '.'
import { Loader } from './loaders'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCashRegister, faBoxesStacked, faBoxDollar } from '@fortawesome/pro-light-svg-icons'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import CryptoJS from 'crypto-js'
import { Buffer } from 'buffer'
import axios from 'axios'
import Cookies from 'js-cookie'


const Home = () => {
    const [time, setTime] = useState(new Date())
    const [user, setUser] = useState({})
    const [turnus, setTurnus] = useState({})
    const [area, setArea] = useState({})
    const [status, setStatus] = useState('loading')
    const cookieUser = Cookies.get('user')
    const dateBase = localStorage.getItem('turnus')
    const userBase = CryptoJS.AES.decrypt(cookieUser, process.env.REACT_APP_CRYPT_TOKEN).toString(CryptoJS.enc.Utf8)
    const userId = Buffer.from(userBase, 'base64').toString('utf-8').split('-')[0]
    const turnusId = (Buffer.from(dateBase, 'base64').toString('utf-8')).split(':')[0]
    const turnusState = (Buffer.from(dateBase, 'base64').toString('utf-8')).split(':')[2]

    const getUser = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'users',
                    type: 'id',
                    specific: parseInt(userId)
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const turnusData = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'turnuses',
                    type: 'id',
                    specific: parseInt(turnusId)
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const areaData = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'areas',
                    type: 'id',
                    specific: parseInt(response.data[0].area)
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            setUser(response.data[0])
            setTurnus(turnusData.data[0])
            setArea(areaData.data[0])
            setStatus('loaded')
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getUser()
        setInterval(() => {
            setTime(new Date())
        }, 1000)
    }, [])

    const zeroCheck = (num) => {
        return num < 10 ? '0' + num : num
    }

    return (
        <>
            <Topmenu />
            {status === 'loading' ?
                <Loader />:
                <div className="main d-flex justify-content-center align-items-center flex-column">
                    <p className="main__name">Vítej, {user?.fullname}</p>
                    {turnusState === 'on' ?
                    <p className='main__user-info'>{area?.name}, {turnus?.name}</p>:
                    <p className='main__user-info'>Momentálně není aktivní žádný turnus</p>
                    }
                    <p className='main__time'>{zeroCheck(new Date(time).getHours())}:{zeroCheck(new Date(time).getMinutes())}</p>
                    <Row className="rows-col-2">
                        {turnusState === 'on' &&
                            <Col>
                                <Link to='/bufet' className='menu-item'>
                                    <FontAwesomeIcon icon={faCashRegister} />
                                    <p>Kasa</p>
                                </Link>
                            </Col>
                        }
                        {(user?.permisions > 1) &&
                            <Col>
                                <Link to='/sklad' className='menu-item'>
                                    <FontAwesomeIcon icon={faBoxesStacked} />
                                    <p>Sklad</p>
                                </Link>
                            </Col>
                        }
                        {(user?.permisions > 2) &&
                            <Col>
                                <Link to='/objednavky' className='menu-item'>
                                    <FontAwesomeIcon icon={faBoxDollar} />
                                    <p>Objednávky</p>
                                </Link>
                            </Col>
                        }
                    </Row>
                </div>
            }
        </>
    )
}

export default Home