import React, { useContext, useState, useEffect } from 'react'
import { AppContext } from './Settings'
import SettingsList from './SettingsList'
import { Loader } from '../loaders'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faXmark } from '@fortawesome/pro-light-svg-icons'
import axios from 'axios'
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap'

const Turnuses = () => {
    const [turnuses, setTurnuses] = useState([])
    const [areas, setAreas] = useState([])
    const [status, setStatus] = useState('fetching')
    const [name, setName] = useState('')
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')
    const [area, setArea] = useState('')
    const { setComponent } = useContext(AppContext)
    const getTurnuses = async () => {
        try {
            const responseTurnuses = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'turnuses'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            const responseAreas = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'bufet',
                    table: 'areas'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            setTurnuses(responseTurnuses.data)
            setAreas(responseAreas.data)
            setStatus('fetched')
        } catch (err) {
            console.log(err);
        }
    }

    const addTurnus = async () => {
        if (dateFrom > dateTo) return toast.error('Datum od je větší než datum do')
        if (area === 'null') return toast.error('Není vybrán areál')
        const data = {
            name: name,
            dateFrom: dateFrom,
            dateTo: dateTo,
            area: area
        }
        var dataJSON = JSON.stringify(data)
        try {
            await axios.post(process.env.REACT_APP_API_URL + '/site', {
                    data: dataJSON
                },
                {
                    params: {
                        database: 'bufet',
                        table: 'turnuses',
                    },
                    headers: {
                        Authorization: process.env.REACT_APP_API_TOKEN
                    }
                }
            )
            setName('')
            setDateFrom('')
            setDateTo('')
            setArea('null')
            getTurnuses()
            toast.success(`Turnus ${name} přidán`)
        } catch (err) {
            console.log(err);
            toast.error(`Při přidávání turnusu ${name} nastala chyba`)
        }
    }

    const deleteTurnus = async (id) => {
        try {
            await axios.delete(process.env.REACT_APP_API_URL + '/site', {
                data: {
                    type: 'id',
                    specific: id
                },
                params: {
                    database: 'bufet',
                    table: 'turnuses'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            })
            getTurnuses()
            toast.success(`Turnus odstraněn`)
        } catch (err) {
            toast.error(`Při odstraňování turnusu nastala chyba`)
            console.log(err);
        }
    }

    useEffect(() => {
        getTurnuses()
    }, [])

    return (
        <div className='setting'>
            <div className="setting__header">
                <button className='btn' onClick={() => {setComponent(<SettingsList />)}}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <h3>Nastavení turnusů ({new Date().getFullYear()})</h3>
            </div>
            <div className="setting__body">
                <Row>
                    <Col>
                        {status === 'fetching' ?
                            <Loader />:
                            areas.map(area => {
                                return (
                                    <div className='area' key={area.id}>
                                        <h2>{area.name}</h2>
                                        <div>
                                            {turnuses.sort((a, b) => (a.dateFrom > b.dateFrom) ? 1 : -1).map(turnus => {
                                                if (area.id === turnus.area) {
                                                    var classes = 'turnus'
                                                    var disabled = false
                                                    const today = new Date()
                                                    if (today >= turnus.dateFrom && today <= turnus.dateTo) {
                                                        classes = classes + ' current'
                                                        disabled = true
                                                    } else if (today > turnus.dateTo) {
                                                        classes = classes + ' passed'
                                                    }
                                                    return (
                                                        <div className={classes} key={turnus.id}>
                                                            <h3>{turnus.name}</h3>
                                                            <p>{new Date(turnus.dateFrom).getDate()}.&nbsp;
                                                                {new Date(turnus.dateFrom).getMonth() + 1}.&nbsp;
                                                                {new Date(turnus.dateFrom).getFullYear()}&nbsp;
                                                                -&nbsp;
                                                                {new Date(turnus.dateTo).getDate()}.&nbsp;
                                                                {new Date(turnus.dateTo).getMonth() + 1}.&nbsp;
                                                                {new Date(turnus.dateTo).getFullYear()}&nbsp;
                                                            </p>
                                                            <button type='button' className='btn btn-danger' disabled={disabled} onClick={() => {deleteTurnus(turnus.id)}}>
                                                                <FontAwesomeIcon icon={faXmark} />
                                                            </button>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Col>
                    <Col>
                        <div className="form">
                            <div className="form-group">
                                <p>Název turnusu</p>
                                <input type="text" value={name} placeholder="Název turnusu" required onChange={(e) => {setName(e.target.value)}} />
                            </div>
                            <div className="form-group">
                                <p>Od</p>
                                <input type="date" value={dateFrom !== '' ? new Date(dateFrom).getVarDate : ''} placeholder="Od" required onChange={(e) => {setDateFrom(new Date(e.target.value).valueOf() + (new Date().getTimezoneOffset() * 60000))}} />
                            </div>
                            <div className="form-group">
                                <p>Do</p>
                                <input type="date" value={dateTo !== '' ? new Date(dateTo).getVarDate : ''} placeholder="Do" required onChange={(e) => {setDateTo(new Date(e.target.value).valueOf() + 86340000 + (new Date().getTimezoneOffset() * 60000))}} />
                            </div>
                            <div className='form-group'>
                                <p>Areál</p>
                                <select className='form-select' defaultValue='null' onChange={(e) => {setArea(parseInt(e.target.value))}}>
                                    <option value="null" disabled>Vyber areál</option>
                                    <option value="1">Velešín</option>
                                    <option value="2">Dvůr Králové</option>
                                    <option value="3">Velké Meziříčí</option>
                                </select>
                            </div>
                            <button type="submit" className='btn btn-submit' onClick={addTurnus}>Přidat turnus</button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Turnuses